
import axios from "axios";

//export const API_URL = "http://localhost:81/api/";

export const API_URL_LIVE = 'https://api.b2b.sa/api/';
export const API_URL = 'https://api.b2b.sa/api/';
export const RESOURCE_URL= 'https://api.b2b.sa/backend/';


// export const API_URL_LIVE = 'http://127.0.0.1:8000/api/';
// export const API_URL = 'http://127.0.0.1:8000/api/';
// export const RESOURCE_URL= 'http://127.0.0.1:8000/';



//https://techteer.net/b2b/backend/api/general/dashmain
export const client = axios.create({
    baseURL: "https://jsonplaceholder.typicode.com/posts" 
  });

  //baseURL: "https://techteer.net/b2b/backend/api/",
export const clientmain= axios.create({
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

