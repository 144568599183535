import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import api from "../../service/api";
import Nofavourite from "../../component/favourite/nofavourite";
import List from "../../component/favourite/quotelist";

import { useProductStore } from "../../store/useProductStore";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
function Quotes() {
  const { i18n, t } = useTranslation();

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [isError, setIsError] = useState(0);
  const [isLoading, setIsLoading] = useState(0);

  const [pendingsubmission, setPendingSubmission] = useState(0);
  const [submitted, setSubmitted] = useState(0);
  const [quotesubmitted, setQuoteSubmitted] = useState(0);
  const [approved, setApproved] = useState(0);
  const [rejected, setRejected] = useState(0);

  const navigate = useNavigate();

  const { quoteproducts, getQuoteProducts } = useProductStore((state) => ({
    quoteproducts: state.quoteproducts,
    getQuoteProducts: state.getQuoteProducts,
  }));

  const location = useLocation();

  const navigateToAvailableProducts = () => {
    navigate("/availableproducts");
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.load == 1) {
        getQuoteProducts();
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (quoteproducts && quoteproducts.data && quoteproducts.data.length > 0) {
      //nothing
    } else {
      getQuoteProducts();
    }
  }, [getQuoteProducts]);

  useEffect(() => {
    if (quoteproducts && quoteproducts.data && quoteproducts.data.length > 0) {
      setList(quoteproducts);
      setTotal(quoteproducts.data.length);

      setSubmitted(
        quoteproducts.data.filter((value) => value.Status === "Submitted")
          .length
      );
      setPendingSubmission(
        quoteproducts.data.filter(
          (value) => value.Status === "Pending Submission"
        ).length
      );
      setQuoteSubmitted(
        quoteproducts.data.filter((value) => value.Status === "Quote Submitted")
          .length
      );
      setApproved(
        quoteproducts.data.filter((value) => value.Status === "Approved").length
      );
      setRejected(
        quoteproducts.data.filter((value) => value.Status === "Rejected").length
      );
    }

    if (quoteproducts) {
      setIsLoading(1);
    }
  }, [quoteproducts]);


  useEffect(() => {
          

      const fetchList = async () => {
          try {
              api.generic('GET','/general/branchfavouritelist')               
              .then((response)=>{
                  
                  console.log(response.data);
                  setList(response.data);
                  setTotal(response.data.data.length);
                  setIsLoading(1);
              })
              .catch((error) => {
                  setIsError(1);
              })
          } catch (error) {
              setIsError(1);
          }
      };


      fetchList();



      
  }, []);


  return (
    <>
      {isLoading == 0 ? "Loading..." : ""}

      <h1> {t("Insights")}</h1>

      <div class="counts col-12-xs">
        <div class="boxs insights">
          <img src="img/counts-1.png" alt="" />
          <div>{t("PendingSubmission")}</div>
          <h2>{pendingsubmission}</h2>
          <div class="foot"></div>
        </div>
        <div class="boxs insights">
          <img src="img/counts-5.png" alt="" />
          <div>{t("QuoteSubmitted")}</div>
          <h2>{quotesubmitted}</h2>
          <div class="foot"></div>
        </div>
        <div class="boxs insights">
          <img src="img/counts-3.png" alt="" />
          <div>{t("Submitted")}</div>
          <h2>{submitted}</h2>
          <div class="foot"></div>
        </div>
      </div>

      {total > 0 ? (
        <>
          <h1>
            {t("Quotes")}
            {/*
  <button onClick={navigateToAvailableProducts} class="add-product"><span>+</span> Add new fav List</button>
        */}
          </h1>

          <h5></h5>

          <div class="product col-xs-12">
            <List list={list} total={total} pagesize={15} isError={isError} />
          </div>
        </>
      ) : isLoading == 0 ? (
        ""
      ) : (
        ""
      )}
    </>
  );
}
export default Quotes;
