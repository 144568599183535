import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../common/pagination";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Branchfavouritelist(props) {
  const { t, i18n } = useTranslation();

  let PageSize = props.pagesize;

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(0);
  const [isData, setIsData] = useState(0);

  const [favfilter, setFavFilter] = useState("");

  const navigate = useNavigate();

  const navigatetodetail = (Id) => {
    navigate(`/branchfavouriteindividual/${Id}`, { state: { id: Id } });
  };

  const currentTableData = useMemo(() => {
    console.log("worked");
    console.log(props.list);

    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    setIsData(isData + 1);
    console.log(isData);
    if (props.isError == 1) {
      setIsLoading(1);
    }

    if (isData >= 0) {
      setIsLoading(1);
    }

    console.log(props.list.data);
    if (favfilter.length > 0) {
      //if(props.list.data.filter(key => key.Title.toLowerCase() == favfilter.toLowerCase()).length==0) {
      if (
        props.list.data.filter((key) =>
          key.Title.toLowerCase().includes(favfilter.toLowerCase())
        ).length == 0
      ) {
        //    return props.list.data.filter(key => key.branchEnName.toLowerCase() == favfilter.toLowerCase()) ?  props.list.data.filter(key => key.branchEnName.toLowerCase() == favfilter.toLowerCase()).slice(firstPageIndex, lastPageIndex) : [];
        return props.list.data.filter((key) =>
          key.branchEnName.toLowerCase().includes(favfilter.toLowerCase())
        )
          ? props.list.data
              .filter((key) =>
                key.branchEnName.toLowerCase().includes(favfilter.toLowerCase())
              )
              .slice(firstPageIndex, lastPageIndex)
          : [];
      } else {
        //return props.list.data.filter(key => key.Title.toLowerCase() == favfilter.toLowerCase()) ?  props.list.data.filter(key => key.Title.toLowerCase() == favfilter.toLowerCase()).slice(firstPageIndex, lastPageIndex) : [];
        return props.list.data.filter((key) =>
          key.Title.toLowerCase().includes(favfilter.toLowerCase())
        )
          ? props.list.data
              .filter((key) =>
                key.Title.toLowerCase().includes(favfilter.toLowerCase())
              )
              .slice(firstPageIndex, lastPageIndex)
          : [];
      }
    } else {
      return props.list.data
        ? props.list.data.slice(firstPageIndex, lastPageIndex)
        : [];
    }
  }, [currentPage, props, favfilter]);

  return (
    <div className="boxs col-xs-12">
      <div className="filters-top col-xs-12 col-md-7">
        <form>
          <input
            type="text"
            className="search"
            placeholder="Search"
            onChange={(e) => {
              setFavFilter(e.target.value);
            }}
          />
        </form>
      </div>

      <h5>
        <span>
          {favfilter.length > 0 ? "" : props.list.data.length + " Fav list"}{" "}
        </span>
        <div class="filters-top col-xs-12 col-md-6">
          <form action="">
            <div></div>
            <div></div>
            {/*  <input type="text" class="search" placeholder="Search"/> */}
          </form>
        </div>
      </h5>
      <table width="100%" class="table responsive-table">
        <thead>
          {" "}
          <tr>
            <th>ID </th>
            <th>{t("FavListName")}</th>
            <th>{t("Branch")}</th>
            <th>{t("Date")}</th>
            <th>{t("DeliveryPriority")}</th>
            <th>{t("Status")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentTableData.map((data) => {
            return (
              <tr>
                <td class="bold">{data.Id}</td>
                <td class="bold">
                  <a href="#">
                    {data.Title == "" || data.Title == null ? "NA" : data.Title}
                  </a>
                </td>

                <td class="bold">{data.branchEnName}</td>
                <td>
                  {new Intl.DateTimeFormat("en-GB", {
                    month: "long",
                    day: "2-digit",
                    year: "numeric",
                  }).format(new Date(data.LastModifiedOn))}
                </td>
                
                <td class="bold">{data.bcNameq}</td>

                <td data-label="Status">
                  {(() => {
                    if (data.Status === "Submitted") {
                      return <i class="submitted">{data.Status}</i>;
                    } else if (data.Status === "Approved") {
                      return (
                        <i class="submitted" style={{ color: "red" }}>
                          {data.Status}
                        </i>
                      );
                    } else if (data.Status === "Quote Submitted") {
                      return <i class="quota">{data.Status}</i>;
                    } else if (data.Status === "Pending Submission") {
                      return (
                        <i class="submitted" style={{ color: "blue" }}>
                          {data.Status}
                        </i>
                      );
                    } else if (data.Status === "rejected") {
                      return <i class="rejected">{data.Status}</i>;
                    } else {
                      return <i class="submitted">{data.Status}</i>;
                    }
                  })()}
                </td>

                <td>
                  <a onClick={() => navigatetodetail(data.Id)}>
                    <i class="fa fa-eye"></i>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {isLoading == 0 ? <h1 align="center">Loading..</h1> : null}

      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={props.list.data.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
}

export default Branchfavouritelist;
